export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/admin": [8,[2,3]],
		"/(app)/admin/settings": [9,[2,3]],
		"/(app)/ai-agent-manager": [10,[2,4]],
		"/(app)/ai-agent-manager/ai-agents": [11,[2,4]],
		"/(app)/ai-agent-manager/ai-agents/create": [12,[2,4]],
		"/(app)/ai-agent-manager/ai-agents/edit": [13,[2,4]],
		"/(app)/ai-agent-manager/documents": [14,[2,4]],
		"/(app)/ai-agent-manager/functions": [15,[2,4]],
		"/(app)/ai-agent-manager/functions/create": [16,[2,4]],
		"/(app)/ai-agent-manager/functions/edit": [17,[2,4]],
		"/(app)/ai-agent-manager/prompts": [18,[2,4]],
		"/(app)/ai-agent-manager/prompts/create": [19,[2,4]],
		"/(app)/ai-agent-manager/prompts/edit": [20,[2,4]],
		"/(app)/ai-agent-manager/tools": [21,[2,4]],
		"/(app)/ai-agent-manager/tools/create": [22,[2,4]],
		"/(app)/ai-agent-manager/tools/edit": [23,[2,4]],
		"/auth": [27],
		"/(app)/c/[id]": [24,[2]],
		"/(app)/c/[id]/s/[shareId]": [25,[5]],
		"/error": [28],
		"/(app)/playground": [26,[2,6]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';